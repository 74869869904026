.botonPadre{
    background-color: #183153;
    color: white;
    width: calc(100% + 11px);
    border: none;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: -11px;
}

.botonSeleccionado{
    background-color: #244A7D !important;
}

.botonSalir{
    background-color: #ca0000;
    color: white;
    width: calc(100% + 11px);
    border: none;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 100px;
    margin-left: -11px;
}