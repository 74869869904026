.content {
    max-width: 100vw !important;
}

.wi-100vw,
.row {
    width: 100% !important;
}

.flex {
    display: flex;
}

.a-center {
    align-items: center;
}

.j-center {
    justify-content: center;
}

.j-spaceBt {
    justify-content: space-between;
}

.fitContentWidth {
    width: fit-content;
}

.gradientBack {
    background: rgb(70, 240, 175);
    background: linear-gradient(90deg, rgba(70, 240, 175, 1) 0%, rgba(33, 198, 255, 1) 100%);
}

.vertical-carousel .carousel-inner {
    display: flex;
    flex-direction: column;
}

.vertical-carousel .carousel-item div {
    /* height: 100%;
    object-fit: cover; */

}

.br-15 {
    border-radius: 15px;
}

.fw-600 {
    font-weight: 600;
}

.fs-18 {
    font-size: 18px;
}

.fs-26 {
    font-size: 26px;
}

.fs-20 {
    font-size: 20px;
}

.fs-16 {
    font-size: 16px;
}

.fs-14 {
    font-size: 14px;
}

.fs-12 {
    font-size: 12px;
}

.boxShadow {
    -webkit-box-shadow: 0px 1px 15px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 1px 15px -7px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 1px 15px -7px rgba(0, 0, 0, 0.75);
}

.grey {
    color: rgb(168, 168, 168);
}

.pointer {
    cursor: pointer;
}

.unidadContainer {
    height: calc(100vh - 70px);
    position: relative;
    overflow: hidden;
    max-width: 100%;
}

.contentUnidad {
    width: 300vw;
    height: 300vh;
    position: relative;
    transition: transform 0.3s;
}

.initial {
    transform: translate(0, 0);
}

.down {
    transform: translateY(-100vh);
}

.up {
    transform: translateY(100vh);
}

.right {
    transform: translateX(-100vw);
}

.left {
    transform: translateX(100vw);
}

/* Cada div ocupa 100vw x 100vh */
.div1,
.div2,
.div3,
.div4,
.div5 {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 70px);
    overflow: hidden;
}

/* Ubicaciones de los divs */
.div1 {
    top: 0;
    left: 0;
}

.div2 {
    background-color: lightcoral;
    top: 100vh;
    left: 0;
}

.div3 {
    background-color: lightgreen;
    top: -100vh;
    left: 0;
}

.div4 {
    background-color: lightyellow;
    top: 0;
    left: 100vw;
}

.div5 {
    background-color: lightpink;
    top: 0;
    left: -100vw;
}

a {
    text-decoration: none !important;
}

.gradientBackTransparent {
    background: linear-gradient(to bottom,
            rgba(255, 255, 255, 0) 0%,
            /* Transparencia en la parte superior */
            rgba(255, 255, 255, 1) 100%
            /* Sin transparencia en la parte inferior */
        ),
        linear-gradient(90deg,
            rgba(70, 240, 175, 1) 0%,
            rgba(33, 198, 255, 1) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    /* Ajusta la altura según lo que quieras cubrir */
    z-index: -1;
    /* Para asegurarte que esté detrás de otros elementos */
}

.navbarComponent {
    position: sticky !important;
    top: 0px;
    z-index: 1500;
}

.searchDiv {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    background-color: #ffffff;
}

.searchBar {
    background-color: #f0f0f000 !important;
    border: none !important;
}

.searchBar:focus {
    outline: none !important;
    box-shadow: none !important;
}

.form-input {
    border: none !important;
    border-bottom: 1px solid #000000 !important;
    /* Líneas grises para el input */
    border-radius: 0 !important;
    background-color: #f9f9f900 !important;
    /* Fondo claro */
    padding: 10px !important;
    font-size: 16px !important;
}

.form-input:focus {
    outline: none !important;
    box-shadow: none !important;
}

.form-input::placeholder {
    color: #aaa !important;
    /* Color del placeholder */
}

.btn-ingresar {
    background-color: #46F0AF !important;
    /* Color verde claro */
    border: none !important;
    padding: 12px;
    font-size: 16px;
}

.btn-ingresar:hover {
    background-color: #3dcf87;
    /* Sombra de verde al pasar el mouse */
}

/* Eliminar la flecha del Dropdown */
.dropdown-toggle-custom::after {
    display: none !important;
    /* Oculta la flecha */
}

/* Estilo para eliminar el borde azul cuando se selecciona */
.dropdown-toggle-custom:focus,
.dropdown-toggle-custom:active,
.dropdown-toggle-custom:focus-visible {
    outline: none !important;
    /* Elimina el borde azul */
    box-shadow: none !important;
    /* Elimina el efecto de sombra */
    background-color: #f0f0f080;
}

/* Opcional: también puedes evitar cambios de color al hacer clic */
.dropdown-toggle-custom {
    background-color: transparent;
    /* Fondo transparente */
    border: none;
    /* Sin borde */
    padding: 0;
    /* Sin espacio extra alrededor de la imagen */
}

::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    margin: 10px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track:horizontal {
    background: #F0F0F2;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:horizontal {
    background: #BDBDBD;
    border-radius: 5px;
}

.notFoundContainer {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: gray;
}

.notFoundIcon {
    scale: 200%;
    max-width: fit-content !important;
}

.he-50 {
    height: 50px;
}

.carousel-indicators button {
    background-color: rgba(0, 0, 0, 0.544) !important;
}

.carousel-indicators button .active {
    background-color: black !important;
}